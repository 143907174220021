<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Application
          </v-list-item-title>
          <v-list-item-subtitle>
            subtext
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Quicksight Viewer</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          { title: 'TMサンプル', icon: 'mdi-view-dashboard', link: '/dashboard',},
          { title: 'コロナ', icon: 'mdi-view-dashboard', link: '/dashboard2',},
          { title: 'エリア（RLS）', icon: 'mdi-view-dashboard', link: '/dashboard3',},
          { title: 'V-Manage', icon: 'mdi-view-dashboard', link: '/vmng',},
          { title: 'V-Manage（RLS）', icon: 'mdi-view-dashboard', link: '/vmng-rls',},
          { title: 'V-Manage（LIVE）', icon: 'mdi-view-dashboard', link: '/vmng-live',},
        ],
        right: null,
        drawer: null,
      }
    },
  }
</script>