<template>
  <v-container>
    <h3>V-Manage（parameter）</h3>
    <v-row>
      <v-col
        cols="3"
        sm="3"
      >
        <v-text-field
          v-model="userid"
          label="staff_id"
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col
        cols="3"
        sm="3"
      >
        <v-text-field
          v-model="companyid"
          label="company_id"
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col
        cols="3"
        sm="3"
      >
        <v-text-field
          v-model="shopid"
          label="shop_id"
          outlined
          clearable
        ></v-text-field>
      </v-col>
      <v-col
        cols="3"
        sm="3"
      >
        <v-text-field
          v-model="dashboardid"
          label="dashboardid"
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <button v-on:click="getEmbedSession">再描画</button>
    <div id="embeddingContainer"></div>
    <router-link to="/">Go to Home</router-link>
  </v-container>
</template>
<script>
import { embedSession } from 'amazon-quicksight-embedding-sdk'; // eslint-disable-line no-unused-vars
import axios from 'axios'

export default {
  name: 'App',
  data() {
    return {
      name: '',
      userid: 'test_user',
      companyid: '5bc577b5-9456-46a8-828b-0243591f5d5a',
      shopid: '79ca3019-f184-4a31-bd51-35c242c4067c',
      dashboardid: 'e1869700-2cce-4e74-8116-f1026a6fd3b3',
      embedsess: null
    }
  },
  created(){
  },
  mounted(){
    this.getEmbedSession();
  },
  methods: {
    async getEmbedSession() { // eslint-disable-line no-unused-vars
      const element = document.getElementById('embeddingContainer');
      while(element.lastChild){
        element.removeChild(element.lastChild);
      }
      
      let embedUrl = await this.geturl()
      console.log(embedUrl)
      let param = {country: "United States"}
      if (this.companyid) {
        param.company = this.companyid
      }
      if (this.shopid) {
        param.shop = this.shopid
      }
      var containerDiv = document.getElementById("embeddingContainer");
      var options = { // eslint-disable-line no-unused-vars
          url: embedUrl,
          container: containerDiv,
          parameters: param,
          scrolling: "no",
          height: "800px",
          width: "1200px",
          locale: "ja-JP",
          footerPaddingEnabled: true,
          defaultEmbeddingVisualType: "TABLE", // this option only applies to QuickSight console embedding and is not used for dashboard embedding
      };
      console.log(options.parameters)
      this.embedsess = embedSession(options);
    },
    async geturl() {
        let url = ''
        await axios.post('https://i2c6sw1vz9.execute-api.ap-northeast-1.amazonaws.com/prd/', {
          email: this.userid,
          dashboardId: this.dashboardid
        }).then(res => {
          console.log(res)
          console.log(res.data.EmbedUrl)
          url = res.data.EmbedUrl
        }).catch(err => {
          console.log(err)
        })
        return url
    }, 
    async changeparam() {
      const element = document.getElementById('embeddingContainer');
      while(element.lastChild){
        element.removeChild(element.lastChild);
      }
      
      let param = {country: "United States"}
      if (this.companyid) {
        param.company = this.companyid
      }
      if (this.shopid) {
        param.shop = this.shopid
      }
      console.log(param)
      this.embedsess.setParameters(param);
    }
  }
}
</script>
