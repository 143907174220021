<template>
  <v-container>
    <h3>V-Manage（RLS）</h3>
    <v-row>
      <v-col
        cols="3"
        sm="3"
      >
        <v-text-field
          v-model="userid"
          label="staff_id"
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <button v-on:click="getEmbedSession">再描画</button>
    <div id="embeddingContainer"></div>
    <router-link to="/">Go to Home</router-link>
  </v-container>
</template>
<script>
import { embedSession } from 'amazon-quicksight-embedding-sdk'; // eslint-disable-line no-unused-vars
import axios from 'axios'

export default {
  name: 'App',
  data() {
    return {
      name: '',
      userid: 'df22db8e-c1c3-4223-9897-d24c67c00f7a',
    }
  },
  created(){
  },
  mounted(){
    this.getEmbedSession();
  },
  methods: {
    async getEmbedSession() { // eslint-disable-line no-unused-vars
      const element = document.getElementById('embeddingContainer');
      while(element.lastChild){
        element.removeChild(element.lastChild);
      }

      let embedUrl = await this.geturl()
      console.log(embedUrl)
      var containerDiv = document.getElementById("embeddingContainer");
      var options = { // eslint-disable-line no-unused-vars
          url: embedUrl,
          container: containerDiv,
          scrolling: "no",
          height: "800px",
          width: "1200px",
          locale: "ja-JP",
          footerPaddingEnabled: true,
          defaultEmbeddingVisualType: "TABLE", // this option only applies to QuickSight console embedding and is not used for dashboard embedding
      };
      embedSession(options);
    },
    async geturl() {
        console.log(this.userid)
        let url = ''
        //let email = 'quicksightEmbeddedUrlRole@Lambda/' + this.userid
        let email = this.userid
        console.log(email)
        await axios.post('https://z7kwhtvxlk.execute-api.ap-northeast-1.amazonaws.com/dev/', {
          email: email,
          dashboardId: '91bd8e46-5dfe-4321-95d2-ab5dfdcf5408'
        }).then(res => {
          console.log(res)
          console.log(res.data.EmbedUrl)
          url = res.data.EmbedUrl
        }).catch(err => {
          console.log(err)
        })
        return url
    }
  }
}
</script>
