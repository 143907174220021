import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home.vue'
import List from '../components/List.vue'
import Dashboard from '../components/Dashboard.vue'
import Dashboard2 from '../components/Dashboard2.vue'
import Dashboard3 from '../components/Dashboard3.vue'
import DashboardVmng from '../components/DashboardVmng.vue'
import DashboardVmngRLS from '../components/DashboardVmngRLS.vue'
import DashboardVmngLive from '../components/DashboardVmngLive.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/list',
      name: 'list',
      component: List
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/dashboard2',
      name: 'dashboard2',
      component: Dashboard2
    },
    {
      path: '/dashboard3',
      name: 'dashboard3',
      component: Dashboard3
    },
    {
      path: '/vmng',
      name: 'V-Manage',
      component: DashboardVmng
    },
    {
      path: '/vmng-rls',
      name: 'V-Manage（RLS）',
      component: DashboardVmngRLS
    },
    {
      path: '/vmng-live',
      name: 'V-Manage（LIVE）',
      component: DashboardVmngLive
    },
  ]
})