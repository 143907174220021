<template>
  <v-container>
    <h3>エリア（Row Level Security）</h3>
    <!--
    <v-row>
      <v-col
        cols="3"
        sm="3"
      >
        <v-text-field
          v-model="userid"
          label="ユーザー名"
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    -->
    <div id="embeddingContainer"></div>
    <router-link to="/">Go to Home</router-link>
  </v-container>
</template>
<script>
import { embedSession } from 'amazon-quicksight-embedding-sdk'; // eslint-disable-line no-unused-vars
import axios from 'axios'

export default {
  name: 'App',
  data() {
    return {
      name: '',
      userid: 'embededurl_test@infomart.co.jp',
    }
  },
  created(){
  },
  mounted(){
    this.getEmbedSession();
  },
  methods: {
    async getEmbedSession() { // eslint-disable-line no-unused-vars
      let embedUrl = await this.geturl()
      console.log(embedUrl)
      var containerDiv = document.getElementById("embeddingContainer");
      var options = { // eslint-disable-line no-unused-vars
          url: embedUrl,
          container: containerDiv,
          parameters: {
              country: "United States"
          },
          scrolling: "no",
          height: "800px",
          width: "1200px",
          locale: "ja-JP",
          footerPaddingEnabled: true,
          defaultEmbeddingVisualType: "TABLE", // this option only applies to QuickSight console embedding and is not used for dashboard embedding
      };
      embedSession(options);
    },
    async geturl() {
        let url = ''
        await axios.post('https://nlu4kegoqj.execute-api.ap-northeast-1.amazonaws.com/dev/', {
          email: this.userid,
          dashboardId: '47ef661a-43d0-43c0-be54-0b20d586ad45'
        }).then(res => {
          console.log(res)
          console.log(res.data.EmbedUrl)
          url = res.data.EmbedUrl
        }).catch(err => {
          console.log(err)
        })
        return url
    }
  }
}
</script>
